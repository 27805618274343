export const PROJECT_FILTER_STRUCTURE = [
	{
		id: "working",
		label: "진행중",
	},
	{
		id: "completed",
		label: "완료",
	},
	{
		id: "holding",
		label: "보류중",
	},
];

export const LABELING_TYPE_STRUCTURE = [
	{
		id: "tagging",
		label: "태깅",
	},
];

export const PROJECT_STRUCTURE = [
	{
		id: "project",
		label: "프로젝트명",
		columnStyle: { flex: 3 },
	},
	{
		id: "dataset",
		label: "데이터 분류",
		columnStyle: { flex: 1.5 },
	},
	{
		id: "createdAt",
		label: "생성일",
		columnStyle: { flex: 1 },
	},
	{
		id: "completedAt",
		label: "완료일",
		columnStyle: { flex: 1 },
	},
	{
		id: "assignedItemCount",
		label: "전체",
		columnStyle: { width: "88px" },
	},
	{
		id: "workerCompletedItemCount",
		label: "작업",
		columnStyle: { width: "88px" },
	},
	{
		id: "workerSkippedItemCount",
		label: "스킵",
		columnStyle: { width: "88px" },
	},
	{
		id: "reviewerCompletedItemCount",
		label: "검수",
		columnStyle: { width: "88px" },
	},
	{
		id: "reviewerSkippedItemCount",
		label: "스킵검수",
		columnStyle: { width: "88px" },
	},
	{
		id: "status",
		label: "상태",
		columnStyle: { width: "88px" },
	},
];

export const PROJECT_INFO_STRUCTURE = [
	{
		id: "labelType",
		label: "프로젝트 유형",
	},
	{
		id: "content",
		label: "프로젝트 설명",
	},
	{
		id: "datasetName",
		label: "데이터 분류",
	},
	{
		id: "imageFilters",
		label: "사용된 필터",
	},
	{
		id: "assignedItemCount",
		label: "할당된 이미지",
	},
	{
		id: "status",
		label: "상태(진행기간)",
		columnStyle: { width: "88px" },
	},
	{
		id: "updatedAt",
		label: "프로젝트 최종수정일",
	},
];

export const WORKSET_FILTER_STRUCTURE = [
	{
		id: "not_started",
		label: "작업전",
	},
	{
		id: "working",
		label: "작업중",
	},
	{
		id: "worked",
		label: "작업완료",
	},
	{
		id: "reviewing",
		label: "검수중",
	},
	{
		id: "reviewed",
		label: "검수완료",
	},
	{
		id: "completed",
		label: "최종완료",
	},
	{
		id: "holding",
		label: "보류중",
	},
];

export const WORKSET_STRUCTURE = [
	{
		id: "workset",
		label: "워크셋명",
		columnStyle: { flex: 2.5 },
	},
	{
		id: "manager",
		label: "관리자",
		columnStyle: { flex: 1.2 },
	},
	{
		id: "createdAt",
		label: "생성일",
		columnStyle: { flex: 1.2 },
	},
	{
		id: "endedDate",
		label: "종료일",
		columnStyle: { flex: 1.2 },
	},
	{
		id: "worker",
		label: "작업자",
		columnStyle: { flex: 1.2 },
	},
	{
		id: "reviewer",
		label: "검수자",
		columnStyle: { flex: 1.2 },
	},
	{
		id: "assignedItemCount",
		label: "전체",
		columnStyle: { width: "88px" },
	},
	{
		id: "workerCompletedItemCount",
		label: "작업",
		columnStyle: { width: "88px" },
	},
	{
		id: "workerSkippedItemCount",
		label: "스킵",
		columnStyle: { flex: 1.2 },
	},
	{
		id: "reviewerCompletedItemCount",
		label: "검수",
		columnStyle: { width: "88px" },
	},
	{
		id: "reviewerSkippedItemCount",
		label: "스킵검수",
		columnStyle: { width: "88px" },
	},

	{
		id: "status",
		label: "상태",
		columnStyle: { width: "88px" },
	},
	{
		id: "kebab",
		columnStyle: { flex: 1.2, overflow: "visible" },
	},
];

export const IMAGE_INFO_STRUCTURE = [
	{
		id: "datasetName",
		label: "데이터 분류",
	},
	{
		id: "itemFilters",
		label: "사용된 필터",
	},
	{
		id: "assignedCount",
		label: "할당한 이미지",
	},
];

export const EXPORT_FORMAT = [
	{ id: "dx", label: "DX 포맷" },
	{ id: "palette", label: "팔레트 포맷" },
];
