import React from "react";

const ProjectList = React.lazy(() => import("pages/Project/templates/Admin/List"));
const ProjectGenerate = React.lazy(() => import("pages/Project/templates/Admin/Generate"));
const ProjectDetail = React.lazy(() => import("pages/Project/templates/Admin/Detail"));
const ProjectUpdate = React.lazy(() => import("pages/Project/templates/Admin/Update"));
const FormUpdate = React.lazy(() => import("pages/Project/templates/Admin/FormUpdate"));

const WorksetGenerate = React.lazy(() => import("pages/Project/templates/Admin/WorksetGenerate"));
const WorksetDetail = React.lazy(() => import("pages/Project/templates/Admin/WorksetDetail"));

export const AdminProject = {
	List: ProjectList,
	Generate: ProjectGenerate,
	Detail: ProjectDetail,
	Update: ProjectUpdate,
	FormUpdatae: FormUpdate,
};

export const AdminWorkset = {
	Generate: WorksetGenerate,
	Detail: WorksetDetail,
};
