import search from "assets/icons/search/search.svg";
import searchNoMargin from "assets/icons/search/search_no_margin.svg";
import searchDeactivate from "assets/icons/search/search_deactivate.svg";
import deleteKeyword from "assets/icons/search/delete_keyword.svg";

import paginationArrow from "assets/icons/table/arrow.svg";
import paginationEdgeArrow from "assets/icons/table/edgeArrow.svg";
import paginationSearch from "assets/icons/table/search.svg";

import error from "assets/icons/table/error.svg";
import filterClear from "assets/icons/table/filter_clear.svg";
import plus from "assets/icons/table/plus.png";
import plusBlack from "assets/icons/table/plusBlack.svg";
import plusWhite from "assets/icons/table/plusWhite.svg";
import plusDisable from "assets/icons/table/plus_disable.svg";

import modeDark from "assets/icons/darkMode/mode_dark.svg";
import modeLight from "assets/icons/darkMode/mode_light.svg";

import snackError from "assets/icons/snackBar/snackError.svg";

import user from "assets/icons/header/user.svg";
import arrowDown from "assets/icons/header/arrow_down.svg";

import worked from "assets/icons/workplace/worked.svg";
import reviewed from "assets/icons/workplace/reviewed.svg";
import workerSkipped from "assets/icons/workplace/worker_skipped.svg";
import skipped from "assets/icons/workplace/skipped.svg";
import link from "assets/icons/workplace/link.svg";
import prevArrow from "assets/icons/workplace/prev_arrow.svg";
import nextArrow from "assets/icons/workplace/next_arrow.svg";
import edgeArrow from "assets/icons/workplace/edgeArrow.svg";
import stepArrow from "assets/icons/workplace/stepArrow.svg";
import explainDisabled from "assets/icons/workplace/explain_disabled.svg";

import arrowDownDark from "assets/icons/arrow_down_dark.svg";
import arrowDownLight from "assets/icons/arrow_down_light.svg";
import arrowTriangleDown from "assets/icons/arrow_triangle_down.svg";
import arrowTriangleDownBlue from "assets/icons/arrow_triangle_down_blue.svg";
import arrowBackLight from "assets/icons/arrow_back_light.svg";
import arrowBackDark from "assets/icons/arrow_back_dark.svg";
import arrowLast from "assets/icons/arrow_last.svg";
import checked from "assets/icons/checked.svg";
import checkedDisabled from "assets/icons/checked_disabled.svg";
import checkCircle from "assets/icons/check_circle.svg";
import authorityWarning from "assets/icons/authority_warning.svg";
import shortcut from "assets/icons/shortcut.svg";
import deleteIcon from "assets/icons/delete.svg";
import deleteNoMargin from "assets/icons/delete_no_margin.svg";
import deleteNoMarginWhite from "assets/icons/delete_no_margin_white.svg";
import trashCan from "assets/icons/trash_can.svg";
import trashCanNoMargin from "assets/icons/trash_can_no_margin.svg";
import trashCanDisabled from "assets/icons/trash_can_disabled.svg";
import dragHandleHorizontal from "assets/icons/drag_handle_horizontal.svg";
import dragHandleVertical from "assets/icons/drag_handle_vertical.svg";
import kebab from "assets/icons/kebab.svg";
import close from "assets/icons/close.svg";
import memberIcon from "assets/icons/member_management.svg";
import Exclamation from "assets/icons/exclamation.svg";
import NoneFile from "assets/icons/noneFile.svg";
import NoneForm from "assets/icons/noneForm.svg";
import ExplainTooltip from "assets/icons/explain_tooltip.svg";
import CheckedColored from "assets/icons/checked_color.svg";
import stepCompleted from "assets/icons/step_completed.svg";

export const searchIcons = {
	searchDeactivate,
	search,
	searchNoMargin,
	deleteKeyword,
};

export const pagination = {
	edgeArrow: paginationEdgeArrow,
	arrow: paginationArrow,
	search: paginationSearch,
};

export const table = {
	error,
	filterClear,
	plus,
	plusBlack,
	plusWhite,
	plusDisable,
};

export const darkModeIcons = {
	dark: modeDark,
	light: modeLight,
};

export const headerIcons = {
	user,
	arrowDown,
};

export const workplace = {
	worked,
	reviewed,
	workerSkipped,
	skipped,
	link,
	arrowLast,
	prevArrow,
	nextArrow,
	edgeArrow,
	stepArrow,
	explainDisabled,
};

export const snackBarIcons = {
	snackError,
};

export const icons = {
	arrowDownDark,
	arrowDownLight,
	checked,
	CheckedColored,
	checkedDisabled,
	checkCircle,
	authorityWarning,
	shortcut,
	deleteIcon,
	deleteNoMargin,
	deleteNoMarginWhite,
	arrowTriangleDown,
	arrowTriangleDownBlue,
	trashCan,
	trashCanNoMargin,
	trashCanDisabled,
	dragHandleHorizontal,
	dragHandleVertical,
	kebab,
	arrowBackLight,
	arrowBackDark,
	close,
	memberIcon,
	Exclamation,
	NoneFile,
	NoneForm,
	ExplainTooltip,
	stepCompleted,
};
