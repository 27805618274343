/** @jsxImportSource @emotion/react */
/* eslint-disable */
import React, { useState, useMemo, useEffect, CSSProperties, useRef } from "react";
import Label from "components/atoms/Label";
import { Input } from "components/modules/Input";
import DropdownSearch from "components/modules/DropdownSearch";
import { BaseTypes } from "types/common";
import { stylingText } from "libs/styling";
import { icons } from "assets/Icons";
import { COLOR } from "styles/common";
import { Tooltip } from "react-tooltip";

type HeightType = CSSProperties["height"];

interface InputLabelType extends BaseTypes {
	title: string;
	required?: boolean;
	description?: string;
}

type DropDownPropsType = Omit<BaseTypes, "children"> & {
	align?: string;
	isDarkMode?: boolean;
	children?: ({
		isOpen,
		handleOpen,
	}: {
		isOpen: boolean;
		handleOpen: React.DispatchWithoutAction;
		handleMouseLeave?: () => void;
		handleMouseEnter?: () => void;
	}) => JSX.Element;
	arrowImg?: string;
	arrowStyle?: CSSProperties;
	hoverMode?: boolean;
};

type ListPropsType = BaseTypes & {
	isOpen: boolean;
	itemList: Array<{ id: string; label: string; type?: string }>;
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
	resetMessage?: string;
	handleMouseLeave?: () => void;
	handleMouseEnter?: () => void;
	isDarkMode?: boolean;
	itemHeight?: HeightType;
};

interface SearchableListPropsType extends ListPropsType {
	toggleVisibleLength?: number;
}

const Dropdown = {
	Wrapper: ({ className, children }: Pick<BaseTypes, "children" | "className">) => {
		return (
			<div css={wrapper} className={`styleProps ${className}`}>
				{children}
			</div>
		);
	},
	Label: ({ title, required, ...props }: InputLabelType) => (
		<Label css={label} title={title} required={required} {...props} />
	),
	SelectBox: ({
		align,
		value,
		placeholder,
		disabled,
		children,
		arrowImg,
		arrowStyle,
		hoverMode = false,
		isDarkMode = false,
		...props
	}: DropDownPropsType) => {
		const dropdownRef = useRef(null);
		const [isOpen, setIsOpen] = useState(false);

		const handleOpen = () => {
			if (disabled) return false;
			return setIsOpen(prev => !prev);
		};

		const handleMouseEnter = () => {
			if (hoverMode && !disabled) setIsOpen(true);
		};

		const handleMouseLeave = () => {
			if (hoverMode && !disabled) setIsOpen(false);
		};

		useEffect(() => {
			const handleClickOutside = event => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setIsOpen(false);
				}
			};

			document.addEventListener("click", handleClickOutside);
			return () => {
				document.removeEventListener("click", handleClickOutside);
			};
		}, []);

		return (
			<>
				<div
					ref={dropdownRef}
					id="dropdown-select-box"
					css={selectBox(isDarkMode, disabled)}
					onClick={() => handleOpen()}
					onMouseEnter={() => handleMouseEnter()}
					onMouseLeave={() => handleMouseLeave()}
					{...props}
				>
					<Input.Box
						css={input}
						value={value || ""}
						placeholder={placeholder}
						isDarkMode={isDarkMode}
						disabled={disabled}
						readOnly
					/>
					<img
						css={{
							...arrow(align ? align === "DESC" : isOpen, disabled),
							...arrowStyle,
						}}
						src={arrowImg ?? isDarkMode ? icons.arrowDownDark : icons.arrowDownLight}
						alt="dropdownArrow"
					/>
				</div>
				{children && children({ isOpen, handleOpen, handleMouseLeave, handleMouseEnter })}
			</>
		);
	},

	List: ({
		isOpen,
		itemList,
		resetMessage,
		onClick,
		handleMouseLeave,
		handleMouseEnter,
		isDarkMode = false,
		itemHeight = 48,
		...props
	}: ListPropsType) => {
		if (!isOpen) return null;

		return (
			<div
				css={list(itemHeight, isDarkMode, false)}
				onClick={onClick}
				onMouseEnter={() => handleMouseEnter?.()}
				onMouseLeave={() => handleMouseLeave?.()}
				{...props}
			>
				{resetMessage && (
					<div css={item(itemHeight, isDarkMode)} className="reset">
						{resetMessage}
					</div>
				)}
				{itemList?.map(({ id, label }) => {
					return (
						<div css={item(itemHeight, isDarkMode)} key={id} id={id} data-label={label}>
							{label}
						</div>
					);
				})}
			</div>
		);
	},

	SearchableList: ({
		isOpen,
		itemList,
		resetMessage,
		onClick,
		handleMouseLeave,
		isDarkMode = false,
		toggleVisibleLength,
		itemHeight = 48,
	}: SearchableListPropsType) => {
		const [searchTerm, setSearchTerm] = useState("");

		const filteredItems = useMemo(() => {
			return itemList?.filter(item =>
				item.label.toLowerCase().includes(searchTerm.toLowerCase()),
			);
		}, [itemList, searchTerm]);

		useEffect(() => {
			setSearchTerm("");
		}, [isOpen]);

		if (!isOpen) return null;

		return (
			<>
				<div
					css={list(itemHeight, isDarkMode, true)}
					onClick={onClick}
					onMouseLeave={handleMouseLeave}
				>
					<div css={searchInput} onClick={e => e.stopPropagation()}>
						<DropdownSearch
							type="text"
							placeholder="검색어 입력"
							value={searchTerm}
							onChange={e => setSearchTerm(e.target.value)}
						/>
					</div>
					<div css={searchItem}>
						{resetMessage && (
							<div css={item(itemHeight, isDarkMode)} className="reset">
								{resetMessage}
							</div>
						)}
						{filteredItems.map(({ id, label, type }) => {
							return (
								<div css={item(itemHeight, isDarkMode)} key={id} id={id}>
									<div
										id={id}
										data-label={label}
										data-tooltip-id="dropdown-tooltip"
										data-tooltip-content={
											label.length > toggleVisibleLength ? label : ""
										}
										data-tooltip-variant="info"
									>
										{label}
									</div>
									{type && (
										<>
											<p css={hyphenStyle} id={id}>
												-
											</p>
											<div css={pairStyle} id={id}>
												{type}
											</div>
										</>
									)}
								</div>
							);
						})}
					</div>
				</div>
				<Tooltip css={{ zIndex: 10 }} id="dropdown-tooltip" place="bottom-start" />
			</>
		);
	},
};

export default Dropdown;

const ellipsis = {
	overflow: "hidden" as const,
	whiteSpace: "nowrap" as const,
	textOverflow: "ellipsis",
};

const wrapper = {
	position: "relative" as const,
};

const label = {
	...stylingText({ size: "14", weight: "medium" }),
};

const arrow = (isOpen: boolean, disabled?: boolean) => ({
	width: "12px",
	height: "10px",
	marginRight: "14px",
	opacity: disabled ? "0.25" : "1",
	cursor: disabled ? "default" : "pointer",
	transform: isOpen ? `translateY(-1px) rotate(180deg)` : `translateY(0) rotate(360deg)`,
	transition: "transform 0.5s",
});

const selectBox = (isDarkMode: boolean, disabled?: boolean) => ({
	display: "flex",
	alignItems: "center",
	backgroundColor: isDarkMode ? COLOR.gray900 : COLOR.white,
	overflow: "hidden",
	width: "100%",
	height: "48px",
	border: `1px solid ${disabled ? (isDarkMode ? COLOR.gray700 : COLOR.gray400) : COLOR.gray500}`,
	borderRadius: "5px",
	cursor: disabled ? "default" : "pointer",
});

const input = {
	...ellipsis,
	paddingRight: "10px",
	border: "0",
	cursor: "pointer",

	"&:disabled": {
		border: "0",
		cursor: "default",
	},
};

const list = (itemHeight: HeightType, isDarkMode: boolean, isSearchable?: boolean) => ({
	position: "absolute" as const,
	zIndex: "10",
	width: "100%",
	maxHeight: isSearchable
		? `calc(${itemHeight}px * 6 + 74px)`
		: `calc(${itemHeight}px * 6 + 2px)`,
	overflowY: isSearchable ? ("hidden" as const) : ("auto" as const),
	border: `1px solid ${COLOR.gray900}`,
	borderRadius: "5px",
	backgroundColor: isDarkMode ? COLOR.gray800 : COLOR.white,
	color: isDarkMode ? COLOR.gray200 : COLOR.gray900,
	boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
});

const item = (itemHeight: HeightType, isDarkMode: boolean) => ({
	...ellipsis,
	height: itemHeight,
	display: "flex",
	alignItems: "center",
	padding: "0 16px",
	borderBottom: `1px solid ${COLOR.gray400}`,
	...stylingText({ size: "14", weight: "medium" }),
	cursor: "pointer",

	"&.reset": {
		color: COLOR.gray500,
	},

	"&:hover": {
		backgroundColor: isDarkMode ? COLOR.gray600 : COLOR.gray200,
	},

	"&:last-of-type": {
		borderBottom: "none",
	},
});

const searchInput = {
	padding: "12px 8px",
	backgroundColor: COLOR.gray100,
};

const searchItem = { maxHeight: "288px", overflowY: "auto" as const };

const pairStyle = {
	color: COLOR.gray500,
};

const hyphenStyle = {
	margin: "0px 8px",
};
