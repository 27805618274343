import useAlert from "hooks/useAlert";
import useFetch from "hooks/useFetch";
import useHandleHistory from "hooks/useHandleHistory";
import { SetStateAction } from "jotai";
import customAxios from "libs/customAxios";
import { encryption } from "libs/hash";
import { Dispatch } from "react";

export const useDuplicateAPI = (
	id: string,
	setIsError: Dispatch<SetStateAction<boolean>>,
	setId: Dispatch<SetStateAction<string>>,
) => {
	const { setAlertModal } = useAlert();
	const { handleHistoryPush } = useHandleHistory();
	const { request } = useFetch({
		requestFn: (params: any) => {
			return customAxios({
				method: `post`,
				endpoint: `/admin/projects/${id}/duplicate`,
				body: params,
			});
		},
		onSuccess: () => {
			setId("");
			localStorage.setItem(
				"snackState",
				encryption(
					JSON.stringify({
						open: true,
						message: "복제가 완료되었습니다.",
					}),
				),
			);
			handleHistoryPush("projects");
		},
		onError: e => {
			if (e.status === 409) {
				setIsError(true);
			} else {
				setAlertModal(e.detail);
			}
		},
	});

	return request;
};
