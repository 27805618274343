/** @jsxImportSource @emotion/react */
import { snackBarIcons } from "assets/Icons";
import { toast } from "react-toastify";

type toastProps = {
	content: string | (() => JSX.Element);
	autoClose?: number;
	theme?: string;
	iconType?: string;
};

export const showToastMessage = ({
	content,
	autoClose = 5000,
	theme = "dark",
	iconType,
}: toastProps) => {
	const iconSwiper = () => {
		switch (iconType) {
			case "error":
				return <img src={snackBarIcons.snackError} alt="error" />;
			default:
				return null;
		}
	};

	const message = () => {
		if (typeof content === "string") {
			return (
				<div css={toastWrapper}>
					{iconSwiper()}
					{content}
				</div>
			);
		}
		return content();
	};

	toast(message, {
		position: "bottom-center",
		autoClose,
		hideProgressBar: true,
		closeOnClick: true,
		closeButton: false,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme,
	});
};

const toastWrapper = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	gap: "8px",
};
