/** @jsxImportSource @emotion/react */
import React from "react";

const MetadataList = React.lazy(() => import("pages/Metadata/templates/List"));
const MetadataGenerate = React.lazy(() => import("pages/Metadata/templates/Generate"));
const MetadataDetail = React.lazy(() => import("pages/Metadata/templates/Detail"));

export const Metadata = {
	List: MetadataList,
	Generate: MetadataGenerate,
	Detail: MetadataDetail,
};
