/** @jsxImportSource @emotion/react */
import { useAtom } from "jotai";
import Modal from "components/layout/Modal";
import Button from "components/atoms/Button";
import { COLOR } from "styles/common";
import { exportFormatAtom } from "jotai/modal/exportFormatAtom";
import Dropdown from "components/modules/Dropdown";
import { EXPORT_FORMAT } from "pages/Project/templates/Admin/structure";
import { useState } from "react";
import { stylingText } from "libs/styling";
import { useDownload } from "components/modal/Export/api";

export default function ExportModal() {
	const [projectId, setProjectId] = useAtom(exportFormatAtom);
	const [format, setFormat] = useState<{ id: string; label: string }>({ id: "", label: "" });

	const handleClose = () => {
		setProjectId("");
		setFormat({ id: "", label: "" });
	};
	const request = useDownload(projectId, format.id, handleClose);

	return (
		<Modal open={!!projectId}>
			<section css={wrapper}>
				<p css={title}>내보내기</p>
				<div>
					<Dropdown.Wrapper css={{ width: "100%" }}>
						<Dropdown.Label title="내보내기 형식" />
						<Dropdown.SelectBox
							value={format?.label}
							placeholder="유형을 선택해 주세요"
							height="48px"
						>
							{({ isOpen, handleOpen, handleMouseEnter, handleMouseLeave }) => (
								<Dropdown.List
									isOpen={isOpen}
									itemList={EXPORT_FORMAT}
									handleMouseEnter={handleMouseEnter}
									handleMouseLeave={handleMouseLeave}
									onClick={e => {
										handleOpen();
										setFormat({
											id: e.target.id,
											label: EXPORT_FORMAT.find(
												item => item.id === e.target.id,
											)?.label,
										});
									}}
									resetMessage="유형을 선택해 주세요"
								/>
							)}
						</Dropdown.SelectBox>
					</Dropdown.Wrapper>
				</div>
				<Button.Wrapper css={BtnWrapper}>
					<Button.Button css={button} customStyle="default" onClick={handleClose}>
						취소
					</Button.Button>
					<Button.Button
						css={button}
						customStyle="filled"
						onClick={() => {
							request();
						}}
					>
						확인
					</Button.Button>
				</Button.Wrapper>
			</section>
		</Modal>
	);
}

const wrapper = {
	width: "510px",
	maxHeight: "269px",
	padding: "24px",
	textAlign: "center" as const,
	backgroundColor: COLOR.white,
	borderRadius: "5px",
	boxShadow: "0px 2px 6px 2px #00000026",
};

const title = {
	...stylingText({ size: "24", weight: "semiBold" }),
	marginBottom: "24px",
};

const BtnWrapper = {
	display: "flex",
	gap: "24px",
};

const button = {
	width: "219px",
	marginTop: "40px",
};
