import useFetch from "hooks/useFetch";
import customAxios from "libs/customAxios";
import { showToastMessage } from "libs/toastMessage";

export const useDownload = (id: string, formatType: string, handleModal: () => void) => {
	const { request } = useFetch({
		requestFn: () => {
			return customAxios({
				method: `get`,
				endpoint: `/admin/projects/${id}/export`,
				params: {
					formatType,
				},
				needHeader: true,
			});
		},
		onSuccess: async response => {
			const contentDisposition = response.headers.get("Content-Disposition");
			const filenameMatch = contentDisposition?.match(/filename="?(.+)"?/);
			const filename = filenameMatch ? filenameMatch[1] : "default.json"; // 기본 이름 설정
			const fileData = new Blob([JSON.stringify(response.data, null, 2)], {
				type: "application/json",
			});
			const url = URL.createObjectURL(fileData);
			const link = document.createElement("a");
			link.href = url;
			link.download = decodeURIComponent(filename);
			document.body.appendChild(link);
			link.click();
			link.remove();
			URL.revokeObjectURL(url);
			handleModal();
		},
		onError: () => {
			showToastMessage({
				content: "내보내기에 실패했습니다. 다시 시도해 주세요.",
				iconType: "error",
			});
		},
	});
	return request;
};
