/** @jsxImportSource @emotion/react */
import { useAtom } from "jotai";
import Modal from "components/layout/Modal";
import Button from "components/atoms/Button";
import { COLOR } from "styles/common";
import { inputModalAtom } from "jotai/modal/inputModalAtom";
import { useEffect, useState } from "react";
import { stylingText } from "libs/styling";
import { Input } from "components/modules/Input";
import { useDuplicateAPI } from "components/modal/Duplicate/api";

export default function InputModal() {
	const [id, setId] = useAtom(inputModalAtom);
	const [isError, setIsError] = useState(false);
	const request = useDuplicateAPI(id, setIsError, setId);
	const [projectName, setProjectName] = useState<string>("");
	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setProjectName(value);
	};

	const handleClear = () => {
		setProjectName("");
		setIsError(false);
		setId("");
	};

	const handleClose = () => {
		handleClear();
	};

	const handleDuplicate = () => {
		request({ title: projectName });
	};

	useEffect(() => {
		setIsError(false);
	}, [projectName]);

	useEffect(() => {
		if (id === "") {
			handleClear();
		}
	}, [id]);

	return (
		<Modal open={!!id}>
			<section css={wrapper}>
				<p css={title}>프로젝트 복제</p>
				<div>
					<Input.Wrapper css={{ textAlign: "left" }}>
						<Input.Label title="생성할 프로젝트명" />
						<Input.Box
							errorMessage="동일한 프로젝트명이 존재합니다."
							isError={isError}
							value={projectName}
							onChange={handleInput}
						/>
					</Input.Wrapper>
					<pre css={inputInfo}>
						*복제시 해당 프로젝트의 이미지들은 <p css={stress}>릴리즈</p> 처리됩니다.
					</pre>
				</div>
				<Button.Wrapper css={BtnWrapper}>
					<Button.Button
						css={button(isError)}
						customStyle="default"
						onClick={handleClose}
					>
						취소
					</Button.Button>
					<Button.Button
						css={button(isError)}
						customStyle="filled"
						onClick={handleDuplicate}
					>
						확인
					</Button.Button>
				</Button.Wrapper>
			</section>
		</Modal>
	);
}

const wrapper = {
	width: "510px",
	maxHeight: "298px",
	padding: "24px",
	textAlign: "center" as const,
	backgroundColor: COLOR.white,
	borderRadius: "5px",
	boxShadow: "0px 2px 6px 2px #00000026",
};

const title = {
	...stylingText({ size: "24", weight: "semiBold" }),
	marginBottom: "24px",
};

const BtnWrapper = {
	display: "flex",
	gap: "24px",
};

const button = (isError: boolean) => ({
	width: "219px",
	marginTop: isError ? "18px" : "40px",
});

const inputInfo = {
	display: "flex",
	marginTop: "8px",
	flexGrow: 1,
	textAlign: "left" as const,
	...stylingText({ size: "14", weight: "medium" }),
};

const stress = {
	color: "#FF334B",
};
