import { Size, Weight } from "types/commonCSS";
import { stylingText } from "libs/styling";

export const COLOR = {
	main: "#003EAB",
	mainLight: "#2E6CD9",
	mainDark: "#2A62C4",
	white: "#FFFFFF",
	black: "#000000",
	red: "#FF334B",
	gray50: "#FCFCFC",
	gray100: "#F6F6F6",
	gray200: "#E8E8E8",
	gray300: "#DDDDDD",
	gray400: "#CFCFCF",
	gray500: "#9B9B9B",
	gray600: "#727272",
	gray700: "#5E5E5E",
	gray800: "#4B4B4B",
	gray900: "#2C2C2C",
};

export const FONT: Size & Weight = {
	"12": "0.75rem",
	"14": "0.875rem",
	"16": "1rem",
	"20": "1.25rem",
	"24": "1.5rem",
	"28": "1.75rem",
	"30": "1.875rem",
	"32": "2rem",
	"36": "2.25rem",
	"48": "3rem",
	"72": "4.5rem",

	regular: "400",
	medium: "500",
	semiBold: "600",
	bold: "700",
	extraBold: "800",
};

export const BUTTONLAYOUT = {
	width: "100%",
	height: "48px",
	...stylingText({ size: "14" }),
};
