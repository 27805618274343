import { atom } from "jotai";

// export type AlertModalType = {
// 	content: string;
// };

export const detailAlertModalAtom = atom<{ content: string; detailContent: string[] }>({
	content: "",
	detailContent: [],
});
