/** @jsxImportSource @emotion/react */
import React, { useEffect, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "jotai/darkMode";
import Header from "components/modules/Header";
import { showToastMessage } from "libs/toastMessage";
import { decryption } from "libs/hash";
import JsxModal from "components/modal/JSX";
import ExportModal from "components/modal/Export";
import InputModal from "components/modal/Duplicate";
import DetailAlertModal from "components/modal/DetailAlert ";

const AlertModal = React.lazy(() => import("components/modal/Alert"));
const ConfirmModal = React.lazy(() => import("components/modal/Confirm"));
const SkipModal = React.lazy(() => import("components/modal/Skip"));

export default function BaseLayout() {
	const { pathname } = useLocation();
	const darkMode = useAtomValue(darkModeAtom);
	const snackState = JSON.parse(decryption(localStorage.getItem("snackState")));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		if (darkMode) {
			document.body.classList.add("dark-mode");
		} else {
			document.body.classList.remove("dark-mode");
		}
	}, [darkMode]);

	useEffect(() => {
		if (snackState) {
			showToastMessage({ content: snackState.message, iconType: snackState.iconType });
			localStorage.removeItem("snackState");
		}
	}, [snackState]);

	return (
		<>
			<Header />
			<Suspense fallback={null}>
				<section css={wrapper}>
					<Outlet />
				</section>
				<AlertModal />
				<ConfirmModal />
				<SkipModal />
				<JsxModal />
				<ExportModal />
				<InputModal />
				<DetailAlertModal />
			</Suspense>
		</>
	);
}

const wrapper = {
	width: "100vw",
	display: "flex",
	justifyContent: "center",
};
