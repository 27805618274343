/** @jsxImportSource @emotion/react */
import React from "react";
import { BaseTypes } from "types/common";

export interface LabelTypes extends Omit<BaseTypes, "title"> {
	title: string;
	isTitle?: boolean;
	required?: boolean;
}

function Label({ title, isTitle, required, children, ...props }: LabelTypes) {
	return (
		<p css={isTitle ? styledTitle : label} {...props}>
			{typeof title === "string" ? <span>{title}</span> : null}
			{required && <span css={requiredIcon}>*</span>}
			{children}
		</p>
	);
}

export default React.memo(Label);

const label = {
	display: "flex",
	marginBottom: "8px",
};

const requiredIcon = {
	height: "21px",
	marginLeft: "4px",
};

const styledTitle = {
	display: "flex",
	marginBottom: "16px",
};
