/* eslint-disable max-len */
/** @jsxImportSource @emotion/react */
import useHandleHistory from "hooks/useHandleHistory";
import Button from "components/atoms/Button";
import { COLOR } from "styles/common";
import useHeader from "./useHeader";
import { stylingText } from "libs/styling";
import { decryption } from "libs/hash";
import { MENUS } from "constants/path";
import { headerIcons } from "assets/Icons";

export default function Header() {
	const {
		menuRef,
		isOpenMenu,
		isOpenUserPopUp,
		setIsOpenMenu,
		handleUserPopUp,
		handleCloseUserPopUp,
		handleSignOut,
	} = useHeader();
	const { pathname, handleHistoryPush } = useHandleHistory();
	const userData = JSON.parse(decryption(localStorage.getItem("userData")));
	const isSignIn = !!userData?.accessToken;
	const userName = userData?.name;
	const userRole = userData?.role;
	const currentPath = pathname.split("/")[1];

	if (pathname.includes("workPlace")) return null;

	return (
		<div css={layout}>
			<div css={wrapper}>
				<div css={left}>
					<p css={logo} onClick={() => handleHistoryPush("/")}>
						KRISTAG
					</p>
					<div css={navigationMenu}>
						{MENUS[userRole]?.map(({ id, path, label, subMenus }, index) => {
							const selectedMenu = currentPath === id;
							const hasSubMenus = Array.isArray(subMenus) && subMenus.length > 0;

							return hasSubMenus ? (
								<div ref={menuRef} key={index} css={{ position: "relative" }}>
									<Button.Button
										css={menuWrapper(selectedMenu)}
										customStyle="text"
										onClick={() => setIsOpenMenu(prev => (prev ? "" : id))}
									>
										{label}
									</Button.Button>
									{isOpenMenu === id && (
										<div css={subMenuWrapper}>
											{subMenus.map((subItem, index) => {
												const isLast = index === subMenus.length - 1;
												return (
													<Button.Button
														key={subItem.label}
														customStyle="text"
														css={subMenuStyle(isLast)}
														onClick={() => {
															handleHistoryPush(subItem.path);
															setIsOpenMenu("");
														}}
													>
														{subItem.label}
													</Button.Button>
												);
											})}
										</div>
									)}
								</div>
							) : (
								<Button.Button
									key={index}
									css={menuWrapper(selectedMenu)}
									customStyle="text"
									onClick={() => handleHistoryPush(path)}
								>
									{label}
								</Button.Button>
							);
						})}
					</div>
				</div>
				<div css={right}>
					{isSignIn ? (
						<div
							css={{ position: "relative" }}
							onBlur={handleCloseUserPopUp}
							tabIndex={0}
						>
							<div css={name} onClick={handleUserPopUp}>
								<img css={userIcon} alt="user" src={headerIcons.user} />
								<span>{userName ? `${userName} 님` : "-"}</span>
								<img
									css={arrowIcon(isOpenUserPopUp)}
									alt="arrow"
									src={headerIcons.arrowDown}
								/>
							</div>
							{isOpenUserPopUp && (
								<div css={popup}>
									<button
										css={popupBtn}
										onMouseDown={() => handleHistoryPush("/myPage")}
									>
										마이페이지
									</button>
									<button css={popupBtn} onMouseDown={handleSignOut}>
										로그아웃
									</button>
								</div>
							)}
						</div>
					) : (
						<Button.Button
							onClick={() => handleHistoryPush("/signIn")}
							customStyle="filled"
							css={signInBtn}
						>
							로그인 / 회원가입
						</Button.Button>
					)}
				</div>
			</div>
		</div>
	);
}

const layout = {
	zIndex: "100",
	width: "100vw",
	height: "72px",
	border: "1px solid #ECECEC",
	background: "white",
	webkitUserSelect: "none" as const,
	mozUserSelect: "none" as const,
	msUserSelect: "none" as const,
	userSelect: "none" as const,
};

const wrapper = {
	display: "flex",
	justifyContent: "space-between",
	maxWidth: "1320px",
	height: "72px",
	padding: "0 24px",
	margin: "0 auto",
	alignItems: "center",
};

const left = {
	display: "flex",
	alignItems: "center",
};

const right = {};

const logo = {
	cursor: "pointer",
	color: COLOR.main,
	letterSpacing: "1.5px",
	...stylingText({ size: "30", weight: "bold" }),
};

const navigationMenu = {
	width: "100%",
	display: "flex",
	gap: "40px",
	paddingLeft: "80px",
	button: {
		...stylingText({ size: "16" }),
	},
};

const menuWrapper = (isSelected: boolean) => ({
	width: "fit-content",
	color: isSelected ? COLOR.main : COLOR.gray900,
	backgroundColor: "transparent",
	...stylingText({ size: "16", weight: "medium" }),
});

const subMenuWrapper = {
	width: "124px",
	left: "-100%",
	zIndex: 10,
	position: "absolute" as const,
	background: COLOR.white,
	borderRadius: "5px",
	border: `1px solid ${COLOR.gray900}`,
	boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
	...stylingText({ size: "16", weight: "medium" }),
};

const subMenuStyle = (isLast: boolean) => ({
	width: "100%",
	display: "flex",
	justifyContent: "center",
	cursor: "pointer",
	padding: "12px 0",
	borderBottom: isLast ? "none" : `1px solid ${COLOR.gray400}`,
});

const name = {
	cursor: "pointer",
	display: "flex",
	alignItems: "center",
	...stylingText({ size: "16", weight: "medium" }),
};

const userIcon = {
	width: "48px",
	height: "48px",
};

const arrowIcon = (isOpen: boolean) => ({
	width: "48px",
	height: "48px",
	transition: "transform 0.5s",
	transform: `${isOpen ? "rotate(180deg)" : "rotate(360deg)"}`,
	transformStyle: "preserve-3d" as const,
});

const popup = {
	zIndex: 10,
	position: "absolute" as const,
	border: `1px solid ${COLOR.gray400}`,
	borderRadius: "8px",
	marginTop: "4px",
	minWidth: "155px",
	width: "100%",
	height: "82px",
	left: 0,
	background: COLOR.white,
	boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
};

const popupBtn = {
	padding: "10px 16px",
	width: "100%",
	display: "flex",
	justifyContent: "flex-start",
	...stylingText({ size: "14", weight: "medium", color: COLOR.gray700 }),
};

const signInBtn = {
	width: "156px",
	height: "48px",
	borderRadius: "38px",
	...stylingText({ size: "16", weight: "medium" }),
};
