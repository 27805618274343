import { useCallback } from "react";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import ResponseError from "libs/ResponseError";

export default function useGlobalRejectHandler() {
	const { setAlertModal } = useAlert();
	const { setConfirmModal } = useConfirm();

	const commonErrorHandle = useCallback(
		(error: ResponseError) => {
			const { status, detail } = error;
			switch (status) {
				case 400:
					return setConfirmModal({
						content: `로그인이 필요한 서비스입니다.`,
						rightBtn: {
							label: "확인",
							event: () => {
								localStorage.removeItem("userData");
								window.location.href = "/";
							},
						},
					});
				case 403:
					return setConfirmModal({
						content:
							detail === "Deactivate"
								? "계정이 휴면상태로 전환되었습니다.\n자동으로 로그아웃 됩니다."
								: "사용자 권한을 확인해주세요.",
						rightBtn: {
							label: "확인",
							event: () => {
								localStorage.removeItem("userData");
								window.location.href = "/";
							},
						},
					});
				default:
					return setAlertModal("서버가 원활하지 않습니다. 관리자에게 문의해주세요.");
			}
		},
		[setAlertModal, setConfirmModal],
	);

	return commonErrorHandle;
}
