/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Input } from "components/modules/Input";
import { BaseTypes } from "types/common";
import { stylingText } from "libs/styling";
import { searchIcons } from "assets/Icons";
import { COLOR } from "styles/common";

interface PropsType extends BaseTypes {
	placeholder?: string;
}

/**
 * 드롭다운컴포넌트에서 사용하는 검색 컴포넌트
 */
function DropdownSearch({
	placeholder = "검색어를 입력해 주세요.",
	value,
	onChange,
	...props
}: PropsType) {
	const [isFocus, setIsFocus] = useState(false);
	return (
		<div css={wrapper} {...props}>
			<Input.Box
				css={input}
				id="searchKeyword"
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				onFocus={() => setIsFocus(true)}
				onBlur={() => setIsFocus(false)}
			/>
			<img
				css={searchIcon}
				src={isFocus ? searchIcons.search : searchIcons.searchDeactivate}
				alt="검색"
			/>
		</div>
	);
}

export default React.memo(DropdownSearch);

const wrapper = {
	position: "relative" as const,
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	...stylingText({ size: "14" }),
};

const searchIcon = {
	position: "absolute" as const,
	width: "48px",
	height: "48px",
	right: "0px",
};

const input = {
	height: "48px",
	padding: "12px 48px 12px 16px",
	backgroundColor: COLOR.white,
	border: `1px solid ${COLOR.gray400}`,
	borderRadius: "5px",
	"&:focus": {
		border: `1px solid ${COLOR.gray900}`,
	},
};
