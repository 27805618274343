import React from "react";

const ProjectList = React.lazy(() => import("pages/Project/templates/User/List"));
const WorksetList = React.lazy(() => import("pages/Project/templates/User/WorksetList"));

export const Project = {
	List: ProjectList,
};

export const Workset = {
	List: WorksetList,
};
