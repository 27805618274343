import React, { Suspense } from "react";
import { Route, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import BaseLayout from "components/layout/BaseLayout";
import RestrictRoute, { isSignIn } from "components/layout/RestrictRoute";
import PrivateRoute from "components/layout/PrivateRoute";

import Main from "pages/Main/template";
import { Metadata } from "pages/Metadata/templates";
import { AdminProject, AdminWorkset } from "pages/Project/templates/Admin";
import { Project, Workset } from "pages/Project/templates/User";
import { MemberManagement } from "pages/MemberManagement/templates";
import { ItemManagement } from "pages/ItemManagement/templates";
import { SkipManagement } from "pages/SkipManagement/templates";
import { Data } from "pages/DataCategory/templates";

const Forbidden = React.lazy(() => import("components/templates/Forbidden"));
const SignUp = React.lazy(() => import("pages/SignUp/template"));
const SignIn = React.lazy(() => import("pages/SignIn/template"));
const CompleteSignUp = React.lazy(() => import("components/templates/CompleteSignUp"));
const MyPage = React.lazy(() => import("pages/MyPage/template"));
const WorkPlace = React.lazy(() => import("pages/WorkPlace/template"));

const Root = () => (
	<Routes>
		<Route element={<BaseLayout />}>
			<Route path="/" element={<Main />} />
			<Route path="/*" element={<RestrictRoute allow={!isSignIn} redirect="/" />}>
				<Route path="signIn" element={<SignIn />} />
				<Route path="signUp/*">
					<Route path="" element={<SignUp />} />
					<Route path="completed" element={<CompleteSignUp />} />
				</Route>
			</Route>

			<Route path="/*" element={<RestrictRoute allow={isSignIn} redirect="/signIn" />}>
				<Route path="myPage" element={<MyPage />} />
				<Route element={<PrivateRoute allowedRoles={["worker", "reviewer"]} />}>
					<Route path="project" element={<Project.List />} />
					<Route path="project/:id" element={<Workset.List />} />
					<Route path="workPlace/:worksetId" element={<WorkPlace />} />
				</Route>
				<Route element={<PrivateRoute allowedRoles="manager" />}>
					<Route
						path="memberManagement/*"
						element={
							<Routes>
								<Route path="" element={<MemberManagement.List />} />
								<Route path=":id" element={<MemberManagement.Detail />} />
							</Routes>
						}
					/>
					<Route
						path="dataCategory/*"
						element={
							<Routes>
								<Route path="" element={<Data.List />} />
								<Route path=":id" element={<Data.SubList />} />
							</Routes>
						}
					/>
					<Route
						path="projects/*"
						element={
							<Routes>
								<Route path="" element={<AdminProject.List />} />
								<Route path="generate" element={<AdminProject.Generate />} />
								<Route path=":id" element={<AdminProject.Detail />} />
								<Route path=":id/update" element={<AdminProject.Update />} />
								<Route
									path=":id/formUpdate"
									element={<AdminProject.FormUpdatae />}
								/>
								<Route path=":id/generate" element={<AdminWorkset.Generate />} />
								<Route
									path=":id/detail/:worksetId"
									element={<AdminWorkset.Detail />}
								/>
								<Route path=":id/workPlace/:worksetId" element={<WorkPlace />} />
							</Routes>
						}
					/>
					<Route
						path="metadata/*"
						element={
							<Routes>
								<Route path="" element={<Metadata.List />} />
								<Route path="generate" element={<Metadata.Generate />} />
								<Route path=":id" element={<Metadata.Detail />} />
							</Routes>
						}
					/>
					<Route
						path="item/*"
						element={
							<Routes>
								<Route
									path="itemManagement/*"
									element={
										<Routes>
											<Route path="" element={<ItemManagement.List />} />
											<Route
												path="detail"
												element={<ItemManagement.Detail />}
											/>
										</Routes>
									}
								/>
								<Route
									path="skipManagement/*"
									element={
										<Routes>
											<Route path="" element={<SkipManagement.List />} />
											<Route
												path="detail"
												element={<SkipManagement.Detail />}
											/>
										</Routes>
									}
								/>
							</Routes>
						}
					/>
				</Route>
			</Route>
		</Route>
		<Route
			path="forbidden"
			element={
				<Suspense fallback={null}>
					<Forbidden />
				</Suspense>
			}
		/>
	</Routes>
);

export const router = createBrowserRouter(
	createRoutesFromElements(<Route path="*" element={<Root />} />),
);
