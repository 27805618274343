/** @jsxImportSource @emotion/react */
import React, { ForwardedRef, forwardRef } from "react";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "jotai/darkMode";
import { BaseTypes, ButtonTypes } from "types/common";
import { COLOR, BUTTONLAYOUT } from "styles/common";
import { stylingText } from "libs/styling";

const Button = {
	Wrapper: ({ children, ...props }: BaseTypes) => <div {...props}>{children}</div>,

	Button: forwardRef(
		(
			{ type = "button", children, customStyle, onClick, disabled, ...props }: ButtonTypes,
			ref: ForwardedRef<HTMLButtonElement>,
		) => {
			const isDarkMode = useAtomValue(darkModeAtom);

			const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
				e.preventDefault();
				onClick?.(e);
			};

			return (
				<button
					css={styledButton(disabled, isDarkMode, customStyle)}
					ref={ref}
					type={type}
					onClick={handleClick}
					disabled={disabled}
					{...props}
				>
					{children}
				</button>
			);
		},
	),
};

export default Button;

const disabledStyle = (isDarkMode: boolean) => ({
	cursor: "not-allowed",
	color: isDarkMode ? COLOR.gray700 : COLOR.gray400,
	border: `1px solid ${isDarkMode ? COLOR.gray700 : COLOR.gray400}`,
	backgroundColor: "transparent",

	"&:hover": {
		backgroundColor: "transparent",
	},
	"&:active": {
		backgroundColor: "transparent",
	},
});

const filledButtonStyle = (isDarkMode: boolean) => ({
	...BUTTONLAYOUT,
	padding: "0.5rem 1rem",
	color: "white",
	backgroundColor: COLOR.main,
	border: `1px solid ${COLOR.main}`,
	borderRadius: "5px",
	...stylingText({ weight: "bold" }),

	"&:disabled": {
		...disabledStyle(isDarkMode),
	},
	"&:hover": {
		backgroundColor: "#00328A",
	},
	"&:active": {
		backgroundColor: "#0A1D6B",
	},
});

const outlinedButtonStyle = (isDarkMode: boolean) => ({
	...BUTTONLAYOUT,
	padding: "0.5rem 1rem",
	color: COLOR.gray900,
	backgroundColor: "white",
	border: `1px solid ${COLOR.gray900}`,
	borderRadius: "5px",
	...stylingText({ weight: "medium" }),

	"&:disabled": {
		...disabledStyle(isDarkMode),
	},
	"&:hover": {
		backgroundColor: COLOR.gray100,
	},
	"&:active": {
		backgroundColor: COLOR.gray400,
	},
});

const deleteButtonStyle = (isDarkMode: boolean) => ({
	...BUTTONLAYOUT,
	padding: "0.5rem 1rem",
	color: COLOR.red,
	backgroundColor: "inherit",
	border: `1px solid ${COLOR.red}`,
	borderRadius: "5px",
	...stylingText({ weight: "bold" }),

	"&:disabled": {
		...disabledStyle(isDarkMode),
	},
	"&:hover": {
		color: isDarkMode ? "#E0001A" : COLOR.red,
		border: `1px solid ${isDarkMode ? "#E0001A" : COLOR.red}`,
		backgroundColor: isDarkMode ? "inherit" : "#FFE9EB",
	},
	"&:active": {
		color: isDarkMode ? "#CA0018" : COLOR.red,
		border: `1px solid ${isDarkMode ? "#CA0018" : COLOR.red}`,
		backgroundColor: isDarkMode ? "inherit" : "#FF9FAA",
	},
});

const textButtonStyle = (disabled: boolean) => ({
	...BUTTONLAYOUT,

	"&:": { color: COLOR.gray900 },
	"&:hover": { color: disabled ? "" : COLOR.main },
	"&:disabled": { cursor: "not-allowed" },
});

const styledButton = (disabled: boolean, isDarkMode: boolean, type?: string) => {
	const convertType = type?.toLowerCase();

	switch (convertType) {
		case "filled":
			return filledButtonStyle(isDarkMode);
		case "text":
			return textButtonStyle(disabled);
		case "outline":
			return outlinedButtonStyle(isDarkMode);
		case "delete":
			return deleteButtonStyle(isDarkMode);
		default:
			return outlinedButtonStyle(isDarkMode);
	}
};
